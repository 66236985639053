<template>
  <div>
    <v-btn
      v-for="t in types"
      :key="t.value"
      :outlined="t.value !== type"
      color="primary"
      dark
      @click="setType(t.value)"
      class="mx-1 text-capitalize"
      :ripple="false"
    >
      {{ t.text }}
      <v-icon v-if="t.value === type"> mdi-check </v-icon>
      <v-icon v-else> mdi-circle-outline </v-icon>
    </v-btn>
    <div :class="{ 'element-disabled': !type }" class="mt-3">
      <v-text-field
        :label="formLabel"
        v-model="key"
        :suffix="type === 'file' ? '.json' : ''"
      ></v-text-field>
      <v-alert type="success" text v-if="success"> Parcel saved as geometry widget </v-alert>
      <v-alert type="error" text v-if="error">
        <span v-html="error"></span>
      </v-alert>
      <v-btn color="primary" @click="exportWidget()" class="text-capitalize" :loading="loading">
        {{ buttonLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Parcel from '../../services/parcel';
export default {
  props: {
    parcel: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      type: '',
      key: '',
      types: [
        { text: 'Geometry Widget', value: 'widget' },
        { text: 'File', value: 'file' }
      ],
      loading: false,
      success: false,
      error: ''
    };
  },
  mounted() {
    let fileName = `Export-${this.parcel.number}`;
    const ref = (this.parcel.reference || '').split(' ').join('_');
    if (ref) fileName += `_${ref}`;
    this.key = fileName;
  },
  computed: {
    formLabel() {
      return this.type === 'widget' ? 'Widget Key' : 'File Name';
    },
    buttonLabel() {
      return this.type === 'widget' ? 'Save Widget' : 'Download GeoJSON';
    }
  },
  methods: {
    setType(type) {
      if (this.type === type) {
        this.type = '';
      } else {
        this.type = type;
      }
    },
    async exportWidget() {
      this.success = null;
      this.error = null;
      this.loading = true;
      const parcel = new Parcel({ id: this.parcel.id });
      await parcel.fetch();
      if (this.type === 'file') {
        parcel.exportGeojson();
        this.loading = false;
      } else {
        try {
          await parcel.saveGeoJSONToWidget(this.key, this.$store.getters.user.uid);
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
        } catch (error) {
          this.error = error;
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
